import { ButtonLinkSecondary } from '/features/buildingBlocks/Button'
import { Magnetic } from '/features/buildingBlocks/Magnetic'
import logo from '/images/logo/albron-logo.raw.svg'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Header.css'

export function Header({ layoutClassName = undefined }) {
  return (
    <header className={cx(styles.component, layoutClassName)}>
      <div className={styles.button}>
        <Magnetic>
          <ButtonLinkSecondary href="https://ontdek.albron.nl/" dataX='link-to-ontdek-albron' >Ontdek <Icon icon={logo} /></ButtonLinkSecondary>
        </Magnetic>
      </div>
    </header>
  )
}
